// 密码必须为6-18位字母、数字、特殊符号的：
// const reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{6,18}$/;
const reg = /^(?=.*[a-zA-Z])(?=.*\d).{6,18}$/;
const msg = '密码必须包括6-18位字母、数字';

const VCODE_REG = /\d{6}/;

const PHONE_REG = /^1(3|4|5|7|8|9)\d{9}$/;

const EMAIL_REG = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/

// 以 +08:00 结尾的时间字符串 正则表达式
const timepattern  = /(\+08:00)$/g ;

// 限制城市
const limitcitys = ['深圳','长沙']

const checkPassword = val => {
  if (val == '') {
    return {
      r: false,
      msg: '密码不能为空'
    }
  } else if (val.length > 18 || val.length < 6) {
    return {
      r: false,
      msg: '密码长度不对'
    }
  } else if (!reg.test(val)) {
    return {
      r: false,
      msg
    }
  } else {
    return {
      r: true,
      msg: ''
    }
  }
}

// 是否pc mobile
const IsPC = () => {

  var userAgentInfo = navigator.userAgent;

  var Agents = ["Android", "iPhone",

    "SymbianOS", "Windows Phone",

    "iPad", "iPod"];

  var flagPc = true;

  for (var v = 0; v < Agents.length; v++) {

    if (userAgentInfo.indexOf(Agents[v]) > 0) {

      flagPc = false;

      break;

    }

  }

  return flagPc;

}

// 身份证获取生日
const getBirthdayByIdNO = (IdNO) => {
  let birthday = "";
  if (IdNO.length == 18) {
    birthday = IdNO.substr(6, 8);
    return birthday.replace(/(.{4})(.{2})/, "$1-$2-");
  } else if (IdNO.length == 15) {
    birthday = "19" + IdNO.substr(6, 6);
    return birthday.replace(/(.{4})(.{2})/, "$1-$2-");
  } else {
    return "";
  }
}

// F 女 , M 男 (female 女,male 男)
const getSexByIdNO = (IdNO) => {
  if (IdNO.length == 18) {
    return IdNO.charAt(16) % 2 == 0 ? "F" : "M";
  } else if (IdNO.length == 15) {
    return IdNO.charAt(14) % 2 == 0 ? "F" : "M";
  } else {
    return "";
  }
}

// 自定义格式时间
function formatTime(formatStr) {
  var str = formatStr;
  var Week = ['日', '一', '二', '三', '四', '五', '六'];

  str = str.replace(/yyyy|YYYY/, this.getFullYear());
  str = str.replace(/yy|YY/, (this.getYear() % 100) > 9 ? (this.getYear() % 100).toString() : '0' + (this.getYear() % 100));

  var month = this.getMonth() + 1;
  str = str.replace(/MM/, month > 9 ? month.toString() : '0' + month);
  str = str.replace(/M/g, month);

  str = str.replace(/w|W/g, Week[this.getDay()]);

  str = str.replace(/dd|DD/, this.getDate() > 9 ? this.getDate().toString() : '0' + this.getDate());
  str = str.replace(/d|D/g, this.getDate());

  str = str.replace(/hh|HH/, this.getHours() > 9 ? this.getHours().toString() : '0' + this.getHours());
  str = str.replace(/h|H/g, this.getHours());
  str = str.replace(/mm/, this.getMinutes() > 9 ? this.getMinutes().toString() : '0' + this.getMinutes());
  str = str.replace(/m/g, this.getMinutes());

  str = str.replace(/ss|SS/, this.getSeconds() > 9 ? this.getSeconds().toString() : '0' + this.getSeconds());
  str = str.replace(/s|S/g, this.getSeconds());
  return str;
}

// 校验手机号
function verifyPhone(nums) {
  const regstr = /^1(3|4|5|6|7|8|9)\d{9}$/
  return regstr.test(nums)
}

// 校验身份证
function verifyIdenty(nums) {
  //身份证正则表达式(15位)
  const isIDCard1 = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/;
  //身份证正则表达式(18位)
  const isIDCard2 = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/;
  if(nums.length == 15){
    return isIDCard1.test(nums)
  }else if (nums.length == 18){
    return isIDCard2.test(nums)
  }
}

// 处理以 +08:00 结尾的时间字符串
function handleTime8(datestr){
  if(timepattern.test(datestr)){
    const dateee = new Date(datestr).toJSON();
    return new Date(+new Date(dateee)+8*3600*1000)
  }else{
    return new Date(datestr)
  }
}

// 专场的分院id
const jglist = ['c285adde67bf5d66c3d06fe38d93091e']

export default {
  checkPassword,
  msg,
  IsPC,
  getBirthdayByIdNO,
  getSexByIdNO,
  formatTime,
  verifyPhone,
  verifyIdenty,
  limitcitys,
  timepattern,
  handleTime8,
  jglist,
  VCODE_REG,
  PHONE_REG,
  EMAIL_REG,
}
